// App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import Login from './components/Login';
import Home from './components/Home';
import Users from './components/Users';
import Translation from './components/Translation';
import Loader from './components/Loader';
import SnakeGame from './components/Snake';
import { db } from './components/Dexie';
import './App.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#757ce8',
      main: '#4dab56', // '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
  },
});

function App() {
  const [isLoading, setIsLoading] = useState(true);  // Stato per il caricamento
  const navigate = useNavigate(); // Navigatore per cambiare la pagina

  useEffect(() => {
    const checkAuthLogin = async () => {
      // Esegui la query su Dexie per controllare l'autenticazione
      let result = await db.genericTable.where({ table: 'userProfile', field: 'username' }).toArray();

      // Se non ci sono risultati, reindirizza alla pagina di login
      if (result.length < 1)
        navigate("/login");

      setIsLoading(false); // Caricamento completato
    };

    checkAuthLogin();
  }, [navigate]);

  // Renderizza il loader se il caricamento è in corso
  if (isLoading) {
    return <Loader />;
  }

  // Renderizza il contenuto principale quando il caricamento è completato
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/translations" element={<Translation />} />
        <Route path="/users" element={<Users />} />
        <Route path='/snake' element={<SnakeGame />} />
      </Routes>
    </Provider>
  );
}

export default function AppWithRouter() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  );
}
