import React from 'react';
import { db } from './Dexie';

function Users() {
  const altezzaBottoni = 150;
  const larghezzaBottoni = 250;

  db.strings.toArray();

  return (
    <div className='container-fluid'>
      {altezzaBottoni} + {larghezzaBottoni}
    </div>
  );
}

export default Users;