import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField, Tabs, Tab, Button } from '@mui/material';
import { login } from '../services/authService'; // Funzione per chiamare l'API deadapi
import { db } from '../components/Dexie'; // Importa Dexie per IndexedDB
import logo from '../logo.svg'; // Il logo come prima

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [tabsValue, setTabsValue] = useState('login');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Verifica se i dati di autenticazione sono presenti in IndexedDB al caricamento della pagina
  useEffect(() => {
    const checkAuth = async () => {
      const authKeyRecord = await db.genericTable.get({ field: 'auth_key', table: 'userProfile' });
      if (authKeyRecord) {
        dispatch({ type: 'LOGIN_SUCCESS', payload: authKeyRecord.value });
        navigate('/home'); // Reindirizza alla home se c'è un auth_key valido
      }
    };
    checkAuth();
  }, [dispatch, navigate]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(''); // Reset error when submitting again
    try {
      // Effettua la chiamata API con l'header di autorizzazione
      const response = await login(username, password);

      // Controlla se la risposta ha i dati necessari
      if (response.details.auth_key && response.details.user && response.details.username) {
        // Salva i valori in IndexedDB
        await db.genericTable.put({ field: 'auth_key', table: 'userProfile', value: response.details.auth_key });
        await db.genericTable.put({ field: 'user', table: 'userProfile', value: response.details.user });
        await db.genericTable.put({ field: 'username', table: 'userProfile', value: response.details.username });

        // Aggiorna lo stato globale con Redux
        dispatch({ type: 'LOGIN_SUCCESS', payload: response.details.auth_key });
        navigate('/home'); // Reindirizza alla home page dopo il login
      } else {
        setError('Errore nei dati ricevuti dal server');
      }
    } catch (e) {
      setError('Login fallito');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleLogin}>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12'>
            {error && <p style={{ color: 'red' }}>{error}</p>}
          </div>
          <div className='col-12'>
            <Tabs
              onChange={(event, newValue) => setTabsValue(newValue)}
              value={tabsValue}
              centered>
              <Tab value='login' label="Accedi" className='text-white' />
              <Tab value='register' label="Registrati" className='text-white' />
            </Tabs>
          </div>
          <div className='col-12'>
            <img src={logo} className="App-logo" alt="logo" />
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-lg-6 col-md-8 col-12'>
            <TextField
              onChange={e => {
                setUsername(e.target.value);
                setError(''); // Reset error on change
              }}
              value={username}
              label="Username"
              type='text'
              variant='standard'
              className='custom-input my-2'
              name='username'
              fullWidth
            />
          </div>
        </div>
        <div className='row justify-content-center'>
          <div className='col-lg-6 col-md-8 col-12'>
            <TextField
              onChange={e => {
                setPassword(e.target.value);
                setError(''); // Reset error on change
              }}
              onDoubleClick={() => setShowPassword(!showPassword)}
              value={password}
              type={showPassword ? 'text' : 'password'}
              variant='standard'
              className='custom-input my-2'
              name='password'
              label='Password'
              fullWidth
            />
          </div>
          <div className='col-12'>
            <Button
              type='submit' // Usa solo onSubmit per gestire il login
              className='m-2'
              variant="outlined"
              disabled={isLoading}>
              {isLoading ? 'Caricamento...' : (tabsValue === 'login' ? 'Accedi' : 'Registrati')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Login;
