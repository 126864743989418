import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Pagination, TextField, Divider } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Icona per il tasto di copia
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import HomeIcon from '@mui/icons-material/Home';
import { db } from './Dexie'; // Importa Dexie
import { getTranslations, modifyTranslation, addTranslation, deleteTranslation, newStringTranslation } from '../services/translationService'; // Servizi API

function Translation() {
  const [translations, setTranslations] = useState([]);
  const [editingTranslation, setEditingTranslation] = useState(null); // Stato per la traduzione in modifica
  const [currentPage, setCurrentPage] = useState(1); // Stato per la pagina corrente
  const [itemsPerPage] = useState(10); // Numero di traduzioni per pagina
  const [totalPages, setTotalPages] = useState(1); // Numero totale di pagine
  const [searchQuery, setSearchQuery] = useState(''); // Stato per la ricerca
  const [showAddForm, setShowAddForm] = useState(false); // Stato per mostrare/nascondere il form di aggiunta
  const [newTranslation, setNewTranslation] = useState({ string: '', language: '', description: '' }); // Stato per la nuova traduzione
  const navigate = useNavigate();

  // Funzione per caricare le traduzioni da Dexie in base alla pagina e alla query di ricerca
  const loadTranslationsFromDexie = useCallback(async (page, searchQuery = '') => {
    const offset = (page - 1) * itemsPerPage;
    let results;

    if (searchQuery) {
      // Cerca per stringa o descrizione in Dexie
      results = await db.strings
        .filter(item => 
          item.string.includes(searchQuery) || 
          item.description.includes(searchQuery)
        )
        .offset(offset)
        .limit(itemsPerPage)
        .toArray();
    } else {
      results = await db.strings.offset(offset).limit(itemsPerPage).toArray();
    }

    const count = await db.strings.count(); // Conta il numero totale di traduzioni
    setTranslations(results);
    setTotalPages(Math.ceil(count / itemsPerPage)); // Calcola il numero totale di pagine
  }, [itemsPerPage]);

  const reloadTranslation = async () => {
    await getTranslations();
    loadTranslationsFromDexie(1);
  }

  useEffect(() => {

    const ciao = async () => {
      let lastUpdateDateTime = await db
        .genericTable
        .where({
          table: 'genericData', 
          field: 'lastUpdateDateTime'})
        .toArray();
      let diffDataTime = null;
      if(lastUpdateDateTime.length > 0) {
        lastUpdateDateTime = new Date(lastUpdateDateTime[0].value);
        let nowDateTime = new Date();
        diffDataTime = new Date(nowDateTime.getTime() - lastUpdateDateTime.getTime());
      } else
        lastUpdateDateTime = false;

      if(!lastUpdateDateTime || (diffDataTime.getUTCDate() - 1) >= 1)
        await getTranslations();

      loadTranslationsFromDexie(1);
    }

    ciao();
    
  }, [loadTranslationsFromDexie]);

  // Chiamata API iniziale e salvataggio su Dexie
  useEffect(() => {
    const fetchAndSaveTranslations = async () => {
      try {
        loadTranslationsFromDexie(currentPage); // Carica le traduzioni per la pagina corrente
      } catch (error) {
        console.error("Errore nel caricamento delle traduzioni dall'API:", error);
      }
    };

    fetchAndSaveTranslations();
  }, [currentPage, loadTranslationsFromDexie]);

  // Funzione per modificare una traduzione e aggiornare Dexie
  const handleSaveEdit = async (originalTranslation, updatedTranslation) => {
    const { string, language, description } = updatedTranslation;

    // Controllo se ci sono modifiche
    const isModified = (
      originalTranslation.string !== string ||
      originalTranslation.language !== language ||
      originalTranslation.description !== description
    );

    if (isModified) {
      try {
        await modifyTranslation(originalTranslation, updatedTranslation); // Modifica tramite API
        await db.strings
          .where({string: string, language: language})
          .modify(updatedTranslation); // Aggiorna anche Dexie

        // Aggiorna la lista delle traduzioni con le modifiche
        setTranslations(translations.map(translation =>
          translation.string === originalTranslation.string &&
          translation.language === originalTranslation.language
            ? updatedTranslation : translation
        ));

        alert('Traduzione modificata!');
      } catch (error) {
        console.error("Errore nella modifica della traduzione:", error);
      }
    }

    // Nascondi il form e ripristina l'elemento della lista
    setEditingTranslation(null);
  };

  // Funzione per cancellare una traduzione e aggiornare Dexie
  const handleDelete = async (id) => {
    try {
      await deleteTranslation({string: id.string, language: id.language}); // API call per cancellare
      await db.strings
        .where({string: id.string, language: id.language})
        .delete(); // Cancella anche da Dexie
      setTranslations(await db.strings.toArray()); // Aggiorna lo stato
    } catch (error) {
      console.error("Errore nella cancellazione della traduzione:", error);
    }
  };

  // Funzione per copiare una traduzione
  const handleCopy = async (translation) => {
    try {
      setNewTranslation({ string: translation.string, language: '', description: '' });
      setShowAddForm(true);
      await navigator.clipboard.writeText(translation.description); // Copia negli appunti
      // alert('Traduzione copiata!');
    } catch (error) {
      console.error("Errore nella copia della traduzione:", error);
    }
  };

  // Funzione per aggiungere una nuova traduzione
  const handleAddTranslation = async () => {
    const { string, language, description } = newTranslation;

    try {
      await addTranslation({ string, language, description }); // Aggiungi tramite API
      await db.strings.put({ string, language, description }); // Aggiungi anche a Dexie

      // Aggiorna lo stato delle traduzioni
      setTranslations([{ string, language, description }, ...translations]);

      // Resetta il form e nascondilo
      setNewTranslation({ string: '', language: '', description: '' });
      setShowAddForm(false);

      // alert('Traduzione aggiunta con successo!');
    } catch (error) {
      console.error("Errore nell'aggiunta della traduzione:", error);
    }
  };

  // Funzione per gestire la cancellazione del form di aggiunta
  const handleCancelAdd = () => {
    setNewTranslation({ string: '', language: '', description: '' });
    setShowAddForm(false);
  };

  // Funzione per cambiare pagina
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    loadTranslationsFromDexie(value, searchQuery); // Carica la nuova pagina da Dexie
  };

  // Funzione per gestire la ricerca
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    loadTranslationsFromDexie(currentPage, event.target.value); // Esegui la ricerca su Dexie
  };

  const handleToggleAddForm = async () => {
    if(!showAddForm) {
      let result = await newStringTranslation();
      setNewTranslation({ string: result.details, language: '', description: '' });
    }

    setShowAddForm(!showAddForm);
  };

  return (
    <div>
      <h1>
        Gestione delle Traduzioni
        <IconButton onClick={() => navigate("/")}>
          <HomeIcon color='primary' />
        </IconButton>
      </h1>

      {/* Campo di ricerca */}
      <TextField 
        label="Cerca per numero string o descrizione"
        variant="standard"
        fullWidth
        value={searchQuery}
        onChange={handleSearch}
        className='custom-input'
        style={{ marginBottom: '20px' }}
      />

      {/* Bottone per aggiungere una nuova traduzione */}
      <Button 
        onClick={() => handleToggleAddForm()}
        className='m-2'
        variant="outlined" 
        color="primary" 
      >
        Aggiungi Traduzione
      </Button>

      <Button 
        onClick={() => reloadTranslation()}
        className='m-2'
        variant="outlined" 
        color="primary" 
      >
        Aggiorna traduzioni
      </Button>

      {/* Form di aggiunta traduzione */}
      {showAddForm && (
        <div style={{ marginBottom: '20px' }}>
          <TextField
            label="String"
            variant="standard"
            className='custom-input'
            fullWidth
            value={newTranslation.string}
            onChange={e => setNewTranslation({ ...newTranslation, string: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Lingua"
            variant="standard"
            className='custom-input'
            fullWidth
            value={newTranslation.language}
            onChange={e => setNewTranslation({ ...newTranslation, language: e.target.value })}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Descrizione"
            variant="standard"
            className='custom-input'
            fullWidth
            multiline
            value={newTranslation.description}
            onChange={e => setNewTranslation({ ...newTranslation, description: e.target.value })}
            style={{ marginBottom: '20px' }}
          />
          <Button 
            onClick={handleAddTranslation} 
            variant="outlined" 
            color="primary"
            style={{ marginRight: '10px' }}
          >
            Salva
          </Button>
          <Button 
            onClick={handleCancelAdd} 
            variant="outlined"
            color="error"
          >
            Cancella
          </Button>
        </div>
      )}

      {/* Lista delle traduzioni */}
      <List>
        {translations.map((translation) => (
          editingTranslation && editingTranslation.string === translation.string && editingTranslation.language === translation.language ? (
            // Mostra il form di modifica se l'elemento è in fase di modifica
            <ListItem 
              key={translation.string + "_" + translation.language} >
              <TextField
                label="String"
                variant="outlined"
                fullWidth
                value={editingTranslation.string}
                onChange={e => setEditingTranslation({ ...editingTranslation, string: e.target.value })}
              />
              <TextField
                label="Lingua"
                variant="outlined"
                fullWidth
                value={editingTranslation.language}
                onChange={e => setEditingTranslation({ ...editingTranslation, language: e.target.value })}
              />
              <Divider />
              <TextField
                label="Descrizione"
                variant="outlined"
                fullWidth
                multiline
                value={editingTranslation.description}
                onChange={e => setEditingTranslation({ ...editingTranslation, description: e.target.value })}
              />
              <Button 
                onClick={() => handleSaveEdit(translation, editingTranslation)} 
                variant="contained" 
                color="primary"
              >
                Salva
              </Button>
              <Button 
                onClick={() => setEditingTranslation(null)} 
                variant="contained"
                color="error"
              >
                Cancella
              </Button>
            </ListItem>
          ) : (
            // Mostra l'elemento nella lista se non è in fase di modifica
            <ListItem key={translation.string + "_" + translation.language}>
              <ListItemText
                className="custom-input"
                primary={`ID: ${translation.string} | Lingua: ${translation.language}`}
                secondary={translation.description}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleCopy(translation)}>
                  <ContentCopyIcon />
                </IconButton>
                <IconButton onClick={() => setEditingTranslation(translation)}>
                  <ModeEditIcon color='primary' />
                </IconButton>
                <IconButton onClick={() => handleDelete(translation)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        ))}
      </List>

      {/* Paginazione */}
      <Pagination
        count={totalPages} // Numero totale delle pagine
        page={currentPage} // Pagina corrente
        onChange={handlePageChange} // Funzione per cambiare pagina
        color="primary"
        variant="outlined"
      />
    </div>
  );
}

export default Translation;
