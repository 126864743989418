import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';

const SnakeGame = () => {
  const boardSize = 20; // Dimensione della griglia

  const initialSnake = useMemo(() => [{ x: 10, y: 10 }], []);
  const initialDirection = useMemo(() => ({ x: 1, y: 0 }), []); // Partenza verso destra

  const [snake, setSnake] = useState(initialSnake);
  const [direction, setDirection] = useState(initialDirection);
  const [nextDirection, setNextDirection] = useState(initialDirection); // Prossima direzione per evitare salti
  const [food, setFood] = useState(generateFood());
  const [score, setScore] = useState(0);
  const [bestScore, setBestScore] = useState(localStorage.getItem('bestScore') || 0);
  const [isPlaying, setIsPlaying] = useState(false);
  const gameInterval = useRef(null);

  // Fine del gioco
  const endGame = useCallback(() => {
    setIsPlaying(false);
    if (score > bestScore) {
      setBestScore(score);
      localStorage.setItem('bestScore', score);
    }
    setScore(0);
    setSnake(initialSnake);
    setDirection(initialDirection);
    setNextDirection(initialDirection); // Reset della prossima direzione
  }, [bestScore, initialDirection, initialSnake, score]);

  // Gestione della tastiera per cambiare direzione
  const handleKeydown = useCallback((e) => {
    if (e.key === 'ArrowUp' && direction.y === 0) {
      setNextDirection({ x: 0, y: -1 });
    } else if (e.key === 'ArrowDown' && direction.y === 0) {
      setNextDirection({ x: 0, y: 1 });
    } else if (e.key === 'ArrowLeft' && direction.x === 0) {
      setNextDirection({ x: -1, y: 0 });
    } else if (e.key === 'ArrowRight' && direction.x === 0) {
      setNextDirection({ x: 1, y: 0 });
    }
  }, [direction]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [direction, isPlaying, handleKeydown]);

  // Movimento del serpente
  const moveSnake = useCallback(() => {
    setSnake((prevSnake) => {
      const newSnake = [...prevSnake];
      const head = { x: newSnake[0].x + direction.x, y: newSnake[0].y + direction.y };

      // Controllo collisioni
      if (head.x < 0 || head.y < 0 || head.x >= boardSize || head.y >= boardSize || isCollision(newSnake, head)) {
        endGame();
        return prevSnake;
      }

      newSnake.unshift(head);

      // Se il serpente mangia il cibo
      if (head.x === food.x && head.y === food.y) {
        setScore((prevScore) => prevScore + 1);
        setFood(generateFood());
      } else {
        newSnake.pop(); // Il serpente si muove
      }

      return newSnake;
    });

    // Aggiorniamo la direzione solo dopo aver mosso il serpente
    setDirection(nextDirection);
  }, [direction, nextDirection, endGame, food]);

  useEffect(() => {
    if (isPlaying) {
      gameInterval.current = setInterval(moveSnake, 200);
    } else {
      clearInterval(gameInterval.current);
    }
    return () => clearInterval(gameInterval.current);
  }, [isPlaying, direction, moveSnake]);

  // Genera la posizione casuale del cibo
  function generateFood() {
    return {
      x: Math.floor(Math.random() * boardSize),
      y: Math.floor(Math.random() * boardSize),
    };
  }

  // Controlla collisioni del serpente con se stesso
  const isCollision = (snake, head) => {
    return snake.some((segment) => segment.x === head.x && segment.y === head.y);
  };

  // Reset del gioco
  const resetGame = () => {
    setIsPlaying(false);
    setScore(0);
    setSnake(initialSnake);
    setDirection(initialDirection);
    setNextDirection(initialDirection);
    setFood(generateFood());
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Snake Game</h1>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: `repeat(${boardSize}, 20px)`,
          margin: 'auto',
          width: `${boardSize * 20}px`,
          height: `${boardSize * 20}px`,
          backgroundColor: '#333',
          position: 'relative',
        }}
      >
        {snake.map((segment, index) => (
          <div
            key={index}
            style={{
              gridColumnStart: segment.x + 1,
              gridRowStart: segment.y + 1,
              width: '20px',
              height: '20px',
              backgroundColor: 'limegreen',
              zIndex: index === 0 ? 1 : 0, // La testa del serpente in cima
            }}
          />
        ))}
        <div
          style={{
            gridColumnStart: food.x + 1,
            gridRowStart: food.y + 1,
            width: '20px',
            height: '20px',
            backgroundColor: 'red',
          }}
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <button onClick={() => setIsPlaying(true)} disabled={isPlaying}>
          Play
        </button>
        <button onClick={() => setIsPlaying(false)} disabled={!isPlaying}>
          Pausa
        </button>
        <button onClick={resetGame}>Reset</button>
      </div>

      <div style={{ marginTop: '20px' }}>
        <p>Punteggio: {score}</p>
        <p>Miglior Punteggio: {bestScore}</p>
      </div>
    </div>
  );
};

export default SnakeGame;
