import logo from '../logo.svg'; // Il logo come prima
import React from 'react';

function Loader() {
	return (
		<img src={logo} className="App-logo" alt="logo" />
	);
}

export default Loader;
