import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate'; // Icona adatta per le traduzioni
import LogoutIcon from '@mui/icons-material/Logout'; // Icona per il logout
import GroupIcon from '@mui/icons-material/Group';
import { db } from './Dexie';

function Home() {
  const navigate = useNavigate();
  const altezzaBottoni = 150;
  const larghezzaBottoni = 250;

  const logout = () => {
    db.delete({disableAutoOpen: false});
    navigate("/login");
  };

  const buttonData = [
    { icon: <TranslateIcon fontSize="large" style={{ color: 'white' }} />, label: 'Gestione Traduzioni', color: 'primary.main', onClick: () => navigate('/translations') },
    { icon: <GroupIcon fontSize="large" style={{ color: 'white' }} />, label: 'Users', color: 'primary.main', onClick: () => navigate('/users') },
    { icon: <LogoutIcon fontSize="large" style={{ color: 'white' }} />, label: 'Snake', color: 'primary.main', onClick: () => navigate('/snake') },
    { icon: <LogoutIcon fontSize="large" style={{ color: 'white' }} />, label: 'Logout', color: 'error.main', onClick: () => logout() },
  ];

  return (
    <div className='container-fluid'>
      <div className='row'>
        <h2 className='col-12'>Home</h2>
      </div>
      <div className='row justify-content-center'>

        {buttonData.map((button, index) => (
          <div className='col' key={index}>
            <Button 
              onClick={button.onClick}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                width={larghezzaBottoni} // Larghezza e altezza uguali per creare una forma quadrata
                height={altezzaBottoni}
                bgcolor={button.color} // Colore di sfondo del bottone
                borderRadius={2} // Bordo arrotondato
                color="white" // Colore del testo e icona
              >
                { button.icon }
                <Typography variant="body2" style={{ color: 'white' }}>
                  { button.label }
                </Typography>
              </Box>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
