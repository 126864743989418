const initialState = {
  isAuthenticated: false,
  token: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true, token: action.payload };
    case 'LOGOUT':
      return { ...state, isAuthenticated: false, token: null };
    default:
      return state;
  }
}

export default authReducer;
  